/**
 * prism.js tomorrow night eighties for JavaScript, CoffeeScript, CSS and HTML
 * Based on https://github.com/chriskempson/tomorrow-theme
 * @author Rose Pritchard
 */

pre {
  padding: 16px !important;
}

code[class*='language-'],
pre[class*='language-'] {
  color: #cedceb;
  background: none;
  font-family: 'JetBrains Mono', monospace;
  font-size: 14px;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.2;
  tab-size: 4;
  hyphens: none;
}

/* Code blocks */
pre[class*='language-'] {
  padding: 16px;
  overflow-x: auto;
}

/*
:not(pre) > code[class*='language-'],
pre[class*='language-'] {
}
*/

/* Inline code */
:not(pre) > code[class*='language-'] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.cdata {
  color: #5c6b85;
  font-style: italic;
}

.token.punctuation {
  color: #97aebf;
}

.namespace {
  color: rgb(178 204 214);
}

.token.deleted {
  color: rgb(239 83 80 / 56%);
  font-style: italic;
}

.token.symbol,
.token.property {
  color: rgb(128 203 196);
}

.token.tag,
.token.keyword {
  color: rgb(247 140 108);
}

.token.operator {
  color: #5c6b85;
}

.token.boolean {
  color: rgb(255 105 131);
}

.token.number {
  color: rgb(253 117 139);
}

.token.constant,
.token.function,
.token.builtin,
.token.char {
  color: #40aeff;
}

.token.selector,
.token.doctype {
  color: rgb(199 146 234);
  font-style: italic;
}

.token.attr-name,
.token.inserted {
  color: rgb(173 219 103);
  font-style: italic;
}

.token.string,
.token.url,
.token.entity,
.language-css .token.string,
.style .token.string {
  color: rgb(173 219 103);
}

.token.class-name,
.token.atrule,
.token.attr-value {
  color: rgb(255 203 139);
}

.token.regex,
.token.important,
.token.variable {
  color: rgb(214 222 235);
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

pre.line-numbers {
  position: relative;
  padding-left: 56px !important;
  counter-reset: linenumber;
}

pre.line-numbers > code {
  position: relative;
}

.line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: -16px;
  font-size: 100%;
  left: -44px;
  width: 2.5em;
  letter-spacing: -1px;
  user-select: none;
  padding: 16px 0;
}

.line-numbers-rows > span {
  pointer-events: none;
  display: block;
  counter-increment: linenumber;
}

.line-numbers-rows > span::before {
  content: counter(linenumber);
  color: rgb(255 255 255 / 20%);
  display: block;
  width: 20px;
  text-align: right;
}
