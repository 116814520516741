@font-face {
  font-family: 'JetBrains Mono';
  src: url('./woff2/JetBrainsMono-Regular.woff2') format('woff2'),
    url('./woff/JetBrainsMono-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: url('./woff2/JetBrainsMono-Medium.woff2') format('woff2'),
    url('./woff/JetBrainsMono-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: url('./woff2/JetBrainsMono-Bold.woff2') format('woff2'),
    url('./woff/JetBrainsMono-Bold.woff') format('woff');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'JetBrains Mono';
  src: url('./woff2/JetBrainsMono-ExtraBold.woff2') format('woff2'),
    url('./woff/JetBrainsMono-ExtraBold.woff') format('woff');
  font-weight: 900;
  font-display: swap;
}
